import { calculateStats, calcComparablesSummary } from 'Api/portfolio';

export const mapDevelopmentsList = res => {
  return {
    pagination: res.pagination,
    developments: res.developments.map(development => ({
      id: development?.id.primary.value,
      valuationDate: development?.last_update.primary.value,
      develop_id: {
        primary: development?.develop_id?.primary?.value,
        secondary:
          development?.develop_id?.secondary && development?.develop_id?.secondary[0]?.value
      },
      borrower_id: {
        primary: development?.borrower_id?.primary?.value
      },
      develop_typology: {
        primary: development?.develop_typology?.primary?.value,
        secondary:
          development?.develop_typology?.secondary &&
          development?.develop_typology?.secondary[0]?.value
      },
      province: development?.province?.primary?.value,
      location: {
        primary: development?.location?.primary?.value,
        secondary: development?.location?.secondary && development?.location?.secondary[0]?.value
      },
      built_year: development?.built_year?.primary?.value,
      review_type: development?.review_type?.primary?.value,
      valuation_type: development?.valuation_type?.primary?.value,
      valuer_name: {
        primary: development?.valuer_name?.primary?.value,
        secondary:
          development?.valuer_name?.secondary && development?.valuer_name?.secondary[0]?.value
      },
      last_update: development?.last_update?.primary?.value,
      valuation_status: development?.valuation_status?.primary?.value
    }))
  };
};

const useConfig = {
  multi_family_housing: {},
  single_family_housing: {},
  garage: {},
  storage: {},
  terraced_house: {},
  detached_house: {},
  commercial: {},
  office: {},
  industrial: {},
  // residential: {},
  //rural: {},
  //special_asset: {},
  other: {},
  building: {},
  land: {},
  subtotal: {}
};

let end_current_rent, startCurrentRent;

export const mapDevelopment = (development, valuationDate) => {
  const mainInfo = development.development;
  const {
    booleans,
    areasAndUnitsSum,
    marketComparables,
    mostFrequent,
    isPublished,
    rentPerArea,
    rentValueSum,
    landStatus,
    bedroomCounts,
    states,
    roomsSum,
    adoptedValues,
    highestStar,
    sellersAppraisal
  } = calculateStats(development.assets, development.comparables);

  let vpoOldestDate = null;

  development.assets.forEach(asset => {
    if (asset.vpo_end && asset.vpo_end !== null) {
      const currentDate = new Date(asset.vpo_end);
      if (!vpoOldestDate || currentDate < vpoOldestDate) {
        vpoOldestDate = currentDate;
      }
    }
  });

  if (vpoOldestDate && vpoOldestDate !== null) {
    vpoOldestDate = vpoOldestDate.toISOString().split('T')[0];
  }

  const fechaMasReciente = obtenerFechaMasReciente(development.assets);
  end_current_rent = fechaMasReciente ? true : false;

  const fechaComienzo = obtenerFechaComienzoRenta(development.assets);
  startCurrentRent = fechaComienzo ? true : false;

  redefineWrongKeys(booleans, 'start_current-rent', 'start_current_rent');
  const { lift, pool, ...risks } = booleans;
  const tableComparables = mapComparablesForTable(development.comparables);
  return {
    header: {
      projectName: mainInfo.project_name,
      valuationDate: mainInfo.last_update || valuationDate,
      devId: mainInfo.client_development_id,
      borrowerId: mainInfo.borrower_id,
      visitType: mainInfo.inspection_type
    },
    page1: {
      detail: {
        addressInfo: {
          address: mainInfo.address,
          municipality: mainInfo.municipality,
          province: [mainInfo?.zip_code, mainInfo?.province].filter(Boolean).join(' '),
          use: mostFrequent.use,
          published: isPublished
        },
        identificationFeatures: {
          registryPlot: mainInfo.registry_plot || '-',
          cadastre: mainInfo.cadastral_reference,
          occupancy: mainInfo.occupancy_status || '-',
          yearBuilt: mainInfo.year_built || '-',
          works: mainInfo.works_per || '-'
        },
        locationQuality: {
          rooms: { ...bedroomCounts },
          land: landStatus,
          hospitality: {
            rooms: roomsSum,
            stars: highestStar
          },
          other: { ...states }
        },
        risks: {
          ...risks,
          vpo_end: vpoOldestDate,
          urbanistic_charges: mainInfo.urbanistic_charges,
          rent_end_date: fechaMasReciente,
          rent_start_date: fechaComienzo,
          endCurrentRent: end_current_rent,
          startCurrentRent: startCurrentRent
        },
        market: {
          offer: mainInfo.offer,
          demand: mainInfo.demand,
          potentialBuyer: mainInfo.potential_buyer,
          liquidity: mostFrequent.liquidity
        },
        mainFeatures: {
          buildingQuality: mostFrequent.building_quality,
          lift,
          swimmingPool: pool
        },
        assetsComments: mainInfo.assets_comments
      },
      location: {
        imageCoordinates: mainInfo.lat && mainInfo.lon ? [mainInfo.lat, mainInfo.lon] : null,
        locationQuality: {
          microLocation: mainInfo.micro_location1,
          macroLocation: mainInfo.macro_location
        },
        developmentUnits: {
          areasAndUnits: applyDevUnitsFormatTable(areasAndUnitsSum)
        },
        locationComments: mainInfo.location_comments
      },
      footer: {
        description: {
          comment: mainInfo.valuation_comment
        },
        valuationInfo: {
          valuationMethod: mainInfo.valuation_method,
          salesComparision: mainInfo.time_for_sale,
          estimatedRent: rentValueSum,
          erv: rentPerArea,
          implYield: calculateYield(mainInfo.gross_yield)
        },
        marketValue: getMarketValues(development.assets)
      }
    },
    page2: {
      developmentUnits: {
        areasAndUnits: applyDevUnitsFormatTable(areasAndUnitsSum),
        marketComparables: applyDevUnitsFormatTable(marketComparables, false),
        adoptedValues: applyDevUnitsFormatTable(adoptedValues),
        sellersAppraisal: applyDevUnitsFormatTable(sellersAppraisal)
      },
      anchorProperties: {
        anchorData: filterAnchorsList(getAnchors(development.assets, development.comparables)) //filter first 3 with higher value
      },
      comparables: {
        resume: {
          sale: calcComparablesSummary(tableComparables.sale),
          rent: calcComparablesSummary(tableComparables.rent)
        },
        table: tableComparables,
        map: mapComparablesForMap(development.comparables),
        mapCoordinates: mainInfo.lat && mainInfo.lon ? [mainInfo.lat, mainInfo.lon] : null
      }
    },
    page3: {
      assets: mapAssets(development.assets)
    }
  };
};

function getMarketValues(assets) {
  const totalMarketValue = assets.reduce((acc, asset) => {
    return asset.market_value ? acc + asset.market_value : acc;
  }, 0);

  const totalSqm = assets.reduce((acc, asset) => {
    const area = asset.built_valuation_area;

    // Validar que area exista y no sea null ni undefined antes de sumarlo
    return area !== null && area !== undefined ? acc + area : acc;
  }, 0);

  // Dividir totalMarketValue por el resultado de la sumatoria
  const totalSqmValue = totalMarketValue / totalSqm;

  const totalandValue = assets.reduce((acc, asset) => {
    return asset.land_valuation_area ? acc + asset.land_valuation_area : acc;
  }, 0);

  const totalSqmLandValue = totalMarketValue / totalandValue;

  return {
    marketValue: Math.round(totalMarketValue),
    sqm: Math.round(totalSqmValue),
    sqmLand: Math.round(totalSqmLandValue)
  };
}

function getAnchors(assets, comparables) {
  return assets.reduce((acc, asset) => {
    if (!asset.is_anchor) return acc;
    return [
      ...acc,
      {
        listingType: asset?.use || asset?.use || '-',
        area: asset?.built_valuation_area || '-',
        built: asset?.year_built || '-',
        floor: asset?.floor || '-',
        price: asset?.market_value || '-',
        priceM2: asset?.built_market_value || '-',
        comparablesSheetData: getAnchorComparablesValues(
          comparables.filter(item => item.anchor_id === asset.asset_id) || []
        ) //match anchor comparables by asset id
      }
    ];
  }, []);
}

function getAnchorComparablesValues(comparables) {
  const listing = comparables.filter(
    asset =>
      (asset?.status === 'Published' || asset?.status === 'Unpublished') &&
      asset?.operation === 'Sale'
  );
  const sold = comparables.filter(asset => asset?.operation === 'Sale' && asset?.status === 'Sold');
  const rent = comparables.filter(asset => asset?.operation === 'Rent');
  return [
    { list: formatAnchorSheetItem(listing), key: 'Listing' },
    { list: formatAnchorSheetItem(sold), key: 'Sold' },
    { list: formatAnchorSheetItem(rent), key: 'Rent' }
  ];
}

function formatAnchorSheetItem(list) {
  return [
    {
      key: 'min',
      value: list.length
        ? list.reduce((previous, current) =>
            current.asset_value < previous.asset_value ? current : previous
          )?.asset_value
        : '-',
      squareMeterValue: list.length
        ? list.reduce((previous, current) =>
            current.sqm_value < previous.sqm_value ? current : previous
          ).sqm_value
        : '-'
    },
    {
      key: 'max',
      value: list.length
        ? list.reduce((previous, current) =>
            current.asset_value > previous.asset_value ? current : previous
          ).asset_value
        : '-',
      squareMeterValue: list.length
        ? list.reduce((previous, current) =>
            current.sqm_value > previous.sqm_value ? current : previous
          ).sqm_value
        : '-'
    },
    {
      key: 'AVG',
      value: list.length
        ? list.reduce((acc, { asset_value }) => acc + asset_value, 0) / list.length
        : '-',
      squareMeterValue: list.length
        ? list.reduce((acc, { sqm_value }) => acc + sqm_value, 0) / list.length
        : '-'
    }
  ];
}

function filterAnchorsList(list) {
  list.sort((a, b) => b.price - a.price);
  if (list.length > 2) return list.slice(0, 3);
  return list;
}

function calculateYield(yieldValue) {
  return (yieldValue * 100).toFixed(2);
}

function separateComparablesSellRent(comparables) {
  return comparables.reduce(
    (acc, comparable) => {
      const operation = comparable.operation.toLowerCase();
      if (!Object.keys(acc).includes(operation)) return acc;
      return { ...acc, [operation]: [...acc[operation], comparable] };
    },
    { sale: [], rent: [] }
  );
}

function redefineWrongKeys(obj, key, newKey) {
  Object.defineProperty(obj, newKey, Object.getOwnPropertyDescriptor(obj, key));
  delete obj[key];
}

function applyDevUnitsFormatTable(data, hasToGetSubtotal = true) {
  const config = { ...useConfig };
  Object.keys(config).forEach(key => {
    if (!data[key]) return;
    config[key] = data[key];
  });
  if (hasToGetSubtotal) config.subtotal = getSubtotal(data);

  return Object.values(config).map((item, i) => ({ ...item, id: i + 1 }));
}

function getSubtotal(data) {
  return data
    ? Object.values(data).reduce((sums, obj) => {
        for (const key in obj) {
          if (key === 'oldestDate') {
            if (!sums[key]) {
              sums[key] = obj[key];
              continue;
            }
            sums[key] = new Date(obj[key]) > new Date(sums[key]) ? obj[key] : sums[key];
            continue;
          }

          if (key === 'areaSum') {
            const numericValue = parseFloat(obj[key]);
            sums[key] = (sums[key] || 0) + (isNaN(numericValue) ? 0 : numericValue);
          } else {
            sums[key] = (sums[key] || 0) + obj[key];
          }
        }
        return sums;
      }, {})
    : {};
}

export function mapComparablesForMap(comparables) {
  return comparables.map((comparable, i) => ({
    id: i,
    coordinates: comparable.lat && comparable.lon ? [comparable.lat, comparable.lon].join(',') : '',
    operation: comparable.operation.toLowerCase()
  }));
}

function mapAssets(assets) {
  return assets.map((asset, i) => ({
    id: i + 1,
    asset_id: asset?.asset_id || '-',
    registry_plot: asset?.registry_plot || '-',
    cadastral_ref: asset?.cadastral_reference || '-',
    use: asset?.use || '-',
    tipology: asset?.typology || '-',
    floor: asset?.floor || '-',
    parking_anex: asset?.parking_annex || '-',
    storage_anex: asset?.storage_annex || '-',
    rooms: asset?.bedrooms || '-',
    repair_state: asset?.interior_state_of_repair || '-',
    current_rent: asset?.current_rent || '-',
    area: asset?.built_valuation_area || '-',
    area_source: asset?.built_valuation_source || '-',
    plot_area: asset?.land_valuation_area || '-',
    plot_area_source: asset?.land_valuation_source || '-',
    market_value: asset?.market_value || '-',
    market_value_sqm: asset?.built_market_value || '-',
    estimated_rent: asset?.rent_value || '-',
    rent_price_m2: asset?.rent_price_m2 || null,
    rent: asset?.rent_value || '-',
    gross_yield: asset?.gross_yield || null,
    sellers_appraisal: asset?.seller_av_dt || '-',
    sellers_app_date: asset?.seller_av_dt_date || '-',
    listing_url: asset?.listing_url || '-',
    listing_price: asset?.listing_price || '-'
  }));
}

export function mapComparablesForTable(comparables) {
  const getCharacteristics = comparable =>
    Object.keys(comparable).filter(
      key => (key.startsWith('has_') || key.startsWith('is_')) && comparable[key] === true
    );

  return separateComparablesSellRent(
    comparables.map((comparable, i) => ({
      id: i + 1,
      anchor: comparable?.anchor_id || '-',
      adStatus: comparable?.status || '-',
      use: comparable?.use || '-',
      typology: comparable?.typology || '-',
      area: comparable?.built_area || '-',
      price: comparable?.asset_value || '-',
      priceM2: comparable?.sqm_value || '-',
      dateOut: (comparable?.date_out && comparable?.date_out.split('-').join('/')) || '-',
      yearBuilt: comparable?.year_built || '-',
      floor: comparable?.floor || '-',
      rooms: comparable?.rooms || '-',
      characteristics: getCharacteristics(comparable),
      address: comparable?.address || '-',
      distance: comparable?.distance || '-',
      tom: comparable?.time_published ? `${comparable.time_published} w` : '-',
      link: comparable?.listing_url || '-',
      operation: comparable?.operation || '-'
    }))
  );
}

function obtenerFechaMasReciente(datos) {
  if (!datos || datos.length === 0) {
    return null;
  }

  return (
    datos
      .reduce((fechaMasReciente, elemento) => {
        const fechaElemento = new Date(elemento.end_current_rent);

        // Verificar si la fecha del elemento es válida y más reciente que la almacenada actualmente
        if (!isNaN(fechaElemento) && (!fechaMasReciente || fechaElemento > fechaMasReciente)) {
          fechaMasReciente = fechaElemento;
        }
        return fechaMasReciente;
      }, null)
      ?.toISOString()
      .split('T')[0] || null
  );
}

function obtenerFechaComienzoRenta(datos) {
  if (!datos || datos.length === 0) {
    return null;
  }

  return (
    datos
      .reduce((fechaComienzo, elemento) => {
        const fechaElemento = new Date(elemento.start_current_rent);

        // Verificar si la fecha del elemento es válida y más reciente que la almacenada actualmente
        if (!isNaN(fechaElemento) && (!fechaComienzo || fechaElemento > fechaComienzo)) {
          fechaComienzo = fechaElemento;
        }
        return fechaComienzo;
      }, null)
      ?.toISOString()
      .split('T')[0] || null
  );
}
