const dateFields = window.location.href.includes('portfolio')
  ? [
      'year',
      'avg_weighted_o_date',
      'avg_weighted_d_date',
      'default_date',
      'originate_in_date',
      'publishing_date',
      'last_update',
      'date_out',
      'date_main',
      'last_price',
      'last_pm2'
    ]
  : [
      'year',
      'avg_weighted_o_date',
      'avg_weighted_d_date',
      'default_date',
      'originate_in_date',
      'publishing_date',
      'last_update',
      'date_out',
      'date_main',
      'last_price',
      'last_pm2',
      'area'
    ]; // changes the order sign "-"

export const reversedSortFields = window.location.href.includes('portfolio')
  ? ['status', 'address', 'score', 'time_published', 'area']
  : ['status', 'address', 'score']; // changes the selected label

export const formatSortParams = sorting => {
  const sortingParam = sorting.sort === 'year' ? `${sorting.sort},status,name` : sorting.sort;
  let sortingOrder;
  if (window.location.href.includes('portfolio')) {
    sortingOrder = sorting.order === 'desc' ? '' : '-';
  } else {
    sortingOrder = sorting.order === 'asc' ? '' : '-';
  }
  if (dateFields.includes(sorting.sort)) {
    if (window.location.href.includes('portfolio')) {
      sortingOrder = sorting.order === 'desc' ? '-' : '';
    } else {
      sortingOrder = sorting.order === 'asc' ? '-' : '';
    }
  }
  return `${sortingOrder}${sortingParam}`;
};

export const parseSortParams = sorting => {
  if (sorting) {
    let sort = sorting[0] === '-' ? sorting.substring(1) : sorting;
    sort = sort === `year,status,name` ? 'year' : sort;
    let order;
    if (window.location.href.includes('portfolio')) {
      order = sorting[0] === '-' ? 'asc' : 'desc';
      if (dateFields.includes(sort)) order = sorting[0] === '-' ? 'desc' : 'asc';
    } else {
      order = sorting[0] === '-' ? 'desc' : 'asc';
      if (dateFields.includes(sort)) order = sorting[0] === '-' ? 'asc' : 'desc';
    }

    return { sort, order };
  }

  return {};
};

export const sortAlphabetically = (a, b) => {
  if (a < b) return -1;

  if (a > b) return 1;

  return 0;
};
